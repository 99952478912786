import React from 'react';
import { Search } from 'lucide-react';
import { SeoMeta } from '../components/seo/SeoMeta';

interface Surname {
  name: string;
  pinyin: string;
  meaning: string;
  popularity: number;
  regions: string[];
}

export function ChineseSurnames() {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedSurname, setSelectedSurname] = React.useState<Surname | null>(null);

  const popularSurnames: Surname[] = [
    {
      name: "李",
      pinyin: "Lǐ",
      meaning: "Originally meant 'plum tree'. The Li clan was one of the most prominent families during the Tang Dynasty (618-907 CE). The surname has a rich history in Chinese culture and is also common in Korea (as 'Lee') and Vietnam.",
      popularity: 1,
      regions: ["Throughout China", "Korea (as 'Lee')", "Vietnam", "Singapore"]
    },
    {
      name: "王",
      pinyin: "Wáng",
      meaning: "Means 'king' or 'monarch'. The Wang clan has a history dating back over 4,000 years to the Zhou Dynasty (1046-256 BCE). It was originally given to descendants of ancient Chinese rulers.",
      popularity: 2,
      regions: ["Northern China", "Taiwan", "Hong Kong", "Malaysia"]
    },
    {
      name: "张",
      pinyin: "Zhāng",
      meaning: "Originally meant 'to draw a bow'. Derived from an ancient officer title during the Zhou Dynasty who was in charge of archery. The Zhang clan has produced many notable military strategists and scholars.",
      popularity: 3,
      regions: ["Central China", "Taiwan", "Southeast Asia"]
    },
    {
      name: "刘",
      pinyin: "Liú",
      meaning: "Associated with 'kill' in ancient times but gained prominence as the imperial surname of the Han Dynasty (206 BCE-220 CE). The Liu clan has significantly influenced Chinese literature and politics.",
      popularity: 4,
      regions: ["Southern China", "Northeast China", "Malaysia", "Singapore"]
    },
    {
      name: "陈",
      pinyin: "Chén",
      meaning: "Originated from the State of Chen in modern-day Henan Province. Represents 'morning' or 'exhibition'. The Chen family has historically been associated with commerce and scholarship.",
      popularity: 5,
      regions: ["Southern China", "Fujian Province", "Taiwan", "Southeast Asia"]
    },
    {
      name: "杨",
      pinyin: "Yáng",
      meaning: "Derived from the word for 'poplar tree'. The Yang clan originated from the ancient state of Yang during the Spring and Autumn Period (771-476 BCE).",
      popularity: 6,
      regions: ["Sichuan Province", "Shaanxi Province", "Southeast Asia"]
    },
    {
      name: "黄",
      pinyin: "Huáng",
      meaning: "Means 'yellow' or 'imperial'. Associated with the Yellow Emperor, a legendary Chinese sovereign. The surname represents nobility and imperial authority.",
      popularity: 7,
      regions: ["Southern China", "Guangdong Province", "Taiwan"]
    },
    {
      name: "赵",
      pinyin: "Zhào",
      meaning: "Originated from the State of Zhao during the Warring States period (475-221 BCE). One of the most prestigious surnames in Chinese history.",
      popularity: 8,
      regions: ["Northern China", "Shanxi Province", "Korea (as 'Cho')"]
    },
    {
      name: "吴",
      pinyin: "Wú",
      meaning: "Derived from the State of Wu during the Spring and Autumn Period. Associated with military prowess and leadership.",
      popularity: 9,
      regions: ["Jiangsu Province", "Zhejiang Province", "Southeast Asia"]
    },
    {
      name: "周",
      pinyin: "Zhōu",
      meaning: "Named after the Zhou Dynasty, one of the most influential dynasties in Chinese history. Represents cultural sophistication and philosophical tradition.",
      popularity: 10,
      regions: ["Central China", "Sichuan Province", "Hong Kong"]
    },
    {
      name: "徐",
      pinyin: "Xú",
      meaning: "Means 'slowly' or 'gently'. The surname originated from the State of Xu during the Spring and Autumn Period. Known for producing many scholars and artists.",
      popularity: 11,
      regions: ["Jiangsu Province", "Shanghai", "Eastern China"]
    },
    {
      name: "孙",
      pinyin: "Sūn",
      meaning: "Originally meant 'grandson'. The Sun clan has a strong connection to military strategy, with Sun Tzu being its most famous member.",
      popularity: 12,
      regions: ["Shandong Province", "Northern China", "Korea (as 'Son')"]
    },
    {
      name: "马",
      pinyin: "Mǎ",
      meaning: "Means 'horse'. Associated with the ancient Silk Road and cavalry traditions. The Ma clan has strong ties to both Chinese and Muslim cultures.",
      popularity: 13,
      regions: ["Northwest China", "Gansu Province", "Malaysia"]
    },
    {
      name: "朱",
      pinyin: "Zhū",
      meaning: "Means 'vermilion red'. Was the imperial surname of the Ming Dynasty (1368-1644 CE). Associated with good fortune and prosperity.",
      popularity: 14,
      regions: ["Jiangsu Province", "Zhejiang Province", "Southeast China"]
    },
    {
      name: "胡",
      pinyin: "Hú",
      meaning: "Originally referred to non-Han Chinese peoples from the north. Now associated with wisdom and cultural exchange.",
      popularity: 15,
      regions: ["Anhui Province", "Central China", "Southeast Asia"]
    },
    {
      name: "郭",
      pinyin: "Guō",
      meaning: "Derived from the word for 'outer city wall'. Associated with protection and defense. Has produced many military strategists.",
      popularity: 16,
      regions: ["Shanxi Province", "Northern China", "Taiwan"]
    },
    {
      name: "何",
      pinyin: "Hé",
      meaning: "Means 'what' or 'why'. Originally from the State of He during the Spring and Autumn Period. Known for philosophical traditions.",
      popularity: 17,
      regions: ["Guangdong Province", "Southern China", "Hong Kong"]
    },
    {
      name: "罗",
      pinyin: "Luó",
      meaning: "Possibly derived from the word for 'net' or 'collect'. Has connections to both Han Chinese and minority ethnic groups.",
      popularity: 18,
      regions: ["Guangxi Province", "Southern China", "Vietnam"]
    },
    {
      name: "高",
      pinyin: "Gāo",
      meaning: "Means 'tall' or 'high'. Associated with nobility and high social status. The surname gained prominence during the Tang Dynasty.",
      popularity: 19,
      regions: ["Shandong Province", "Northern China", "Korea (as 'Ko')"]
    },
    {
      name: "林",
      pinyin: "Lín",
      meaning: "Means 'forest'. Associated with nature and growth. Particularly common in southeastern China and among overseas Chinese.",
      popularity: 20,
      regions: ["Fujian Province", "Taiwan", "Southeast Asia"]
    },
    {
      name: "郑",
      pinyin: "Zhèng",
      meaning: "Named after the State of Zheng during the Spring and Autumn Period. Known for diplomatic and administrative traditions.",
      popularity: 21,
      regions: ["Henan Province", "Fujian Province", "Southeast Asia"]
    },
    {
      name: "梁",
      pinyin: "Liáng",
      meaning: "Means 'bridge' or 'beam'. Associated with the Liang Dynasty (502-557 CE) and cultural achievements in literature and arts.",
      popularity: 22,
      regions: ["Sichuan Province", "Central China", "Malaysia"]
    },
    {
      name: "谢",
      pinyin: "Xiè",
      meaning: "Means 'thank' or 'decline'. The Xie clan has a strong tradition in literature and poetry, particularly during the Six Dynasties period.",
      popularity: 23,
      regions: ["Zhejiang Province", "Southern China", "Taiwan"]
    },
    {
      name: "宋",
      pinyin: "Sòng",
      meaning: "Named after the State of Song. Associated with the Song Dynasty (960-1279 CE), known for cultural and technological advancement.",
      popularity: 24,
      regions: ["Henan Province", "Northern China", "Korea"]
    },
    {
      name: "唐",
      pinyin: "Táng",
      meaning: "Named after the Tang Dynasty (618-907 CE). Represents cultural sophistication and the golden age of Chinese civilization.",
      popularity: 25,
      regions: ["Guangdong Province", "Southern China", "Hong Kong"]
    },
    {
      name: "许",
      pinyin: "Xǔ",
      meaning: "Means 'allow' or 'permit'. Originally from the State of Xu. Known for producing scholars and government officials.",
      popularity: 26,
      regions: ["Henan Province", "Eastern China", "Malaysia"]
    },
    {
      name: "韩",
      pinyin: "Hán",
      meaning: "Named after the State of Han. The surname has strong connections to both Chinese and Korean culture.",
      popularity: 27,
      regions: ["Shanxi Province", "Northern China", "Korea (as 'Han')"]
    },
    {
      name: "冯",
      pinyin: "Féng",
      meaning: "Originally meant 'gallop'. Associated with horsemanship and military prowess in ancient China.",
      popularity: 28,
      regions: ["Hebei Province", "Northern China", "Inner Mongolia"]
    },
    {
      name: "邓",
      pinyin: "Dèng",
      meaning: "Named after the ancient State of Deng. Gained prominence during the late 20th century through political leadership.",
      popularity: 29,
      regions: ["Sichuan Province", "Central China", "Guangdong Province"]
    },
    {
      name: "曹",
      pinyin: "Cáo",
      meaning: "Originally referred to officials who managed horses. Famous for the Cao clan's role in the Three Kingdoms period.",
      popularity: 30,
      regions: ["Anhui Province", "Eastern China", "Southeast Asia"]
    },
    {
      name: "曾",
      pinyin: "Zēng",
      meaning: "Originally meant 'already' or 'once'. The Zeng clan has a strong presence in southern China and is known for educational achievements.",
      popularity: 31,
      regions: ["Guangdong Province", "Fujian Province", "Taiwan"]
    },
    {
      name: "彭",
      pinyin: "Péng",
      meaning: "Named after Mount Peng in ancient China. Associated with strength and resilience in Chinese culture.",
      popularity: 32,
      regions: ["Hunan Province", "Southern China", "Southeast Asia"]
    },
    {
      name: "董",
      pinyin: "Dǒng",
      meaning: "Originally meant 'supervisor' or 'overseer'. The Dong clan has a tradition of leadership in both government and business.",
      popularity: 33,
      regions: ["Henan Province", "Northern China", "Singapore"]
    },
    {
      name: "袁",
      pinyin: "Yuán",
      meaning: "Possibly derived from the word for 'long robe'. The Yuan clan has significant influence in Chinese political history.",
      popularity: 34,
      regions: ["Jiangxi Province", "Central China", "Hong Kong"]
    },
    {
      name: "潘",
      pinyin: "Pān",
      meaning: "Associated with water and fluidity. The Pan clan has strong connections to commerce and maritime trade.",
      popularity: 35,
      regions: ["Zhejiang Province", "Coastal China", "Southeast Asia"]
    },
    {
      name: "蒋",
      pinyin: "Jiǎng",
      meaning: "Originally referred to a type of herb. The Jiang clan gained prominence through political leadership in modern Chinese history.",
      popularity: 36,
      regions: ["Zhejiang Province", "Taiwan", "Southeast China"]
    },
    {
      name: "蔡",
      pinyin: "Cài",
      meaning: "Named after the State of Cai. The Cai clan is known for achievements in literature and education.",
      popularity: 37,
      regions: ["Fujian Province", "Taiwan", "Southeast Asia"]
    },
    {
      name: "余",
      pinyin: "Yú",
      meaning: "Means 'surplus' or 'abundance'. The Yu clan has traditions in both scholarship and commerce.",
      popularity: 38,
      regions: ["Jiangxi Province", "Southern China", "Malaysia"]
    },
    {
      name: "杜",
      pinyin: "Dù",
      meaning: "Means 'birchleaf pear tree'. The Du clan has produced many famous poets and scholars throughout history.",
      popularity: 39,
      regions: ["Henan Province", "Northern China", "Vietnam"]
    },
    {
      name: "叶",
      pinyin: "Yè",
      meaning: "Means 'leaf' or 'page'. The Ye clan is associated with literature and education in Chinese culture.",
      popularity: 40,
      regions: ["Zhejiang Province", "Southern China", "Southeast Asia"]
    },
    {
      name: "苏",
      pinyin: "Sū",
      meaning: "Originally meant 'to revive'. The Su clan is renowned for literary achievements, particularly in poetry and prose.",
      popularity: 41,
      regions: ["Jiangsu Province", "Eastern China", "Singapore"]
    },
    {
      name: "魏",
      pinyin: "Wèi",
      meaning: "Named after the State of Wei. Associated with the Three Kingdoms period and military strategy.",
      popularity: 42,
      regions: ["Shanxi Province", "Northern China", "Korea"]
    },
    {
      name: "程",
      pinyin: "Chéng",
      meaning: "Means 'journey' or 'procedure'. The Cheng clan has contributed significantly to Chinese philosophy.",
      popularity: 43,
      regions: ["Henan Province", "Central China", "Southeast Asia"]
    },
    {
      name: "莫",
      pinyin: "Mò",
      meaning: "Originally meant 'sunset' or 'must not'. The Mo clan has connections to both Chinese and Vietnamese cultures.",
      popularity: 44,
      regions: ["Guangxi Province", "Southern China", "Vietnam"]
    },
    {
      name: "江",
      pinyin: "Jiāng",
      meaning: "Means 'river'. The Jiang clan has strong associations with regions along the Yangtze River.",
      popularity: 45,
      regions: ["Jiangxi Province", "Southern China", "Taiwan"]
    },
    {
      name: "石",
      pinyin: "Shí",
      meaning: "Means 'stone' or 'rock'. Symbolizes stability and endurance in Chinese culture.",
      popularity: 46,
      regions: ["Hebei Province", "Northern China", "Inner Mongolia"]
    },
    {
      name: "戴",
      pinyin: "Dài",
      meaning: "Means 'to wear' or 'to respect'. The Dai clan has traditions in scholarship and education.",
      popularity: 47,
      regions: ["Anhui Province", "Eastern China", "Malaysia"]
    },
    {
      name: "段",
      pinyin: "Duàn",
      meaning: "Originally meant 'section' or 'segment'. Has historical connections to minority ethnic groups in China.",
      popularity: 48,
      regions: ["Yunnan Province", "Southwest China", "Vietnam"]
    },
    {
      name: "萧",
      pinyin: "Xiāo",
      meaning: "Named after a type of flute. The Xiao clan has strong traditions in arts and music.",
      popularity: 49,
      regions: ["Zhejiang Province", "Southern China", "Southeast Asia"]
    },
    {
      name: "田",
      pinyin: "Tián",
      meaning: "Means 'field' or 'farmland'. The Tian clan has deep connections to agriculture and land management.",
      popularity: 50,
      regions: ["Shandong Province", "Northern China", "Korea"]
    }
  ];

  const filteredSurnames = popularSurnames.filter(surname =>
    surname.pinyin.toLowerCase().includes(searchTerm.toLowerCase()) ||
    surname.meaning.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <SeoMeta
        title="Chinese Surnames: Origins, Meanings, and History"
        description="Discover the rich history and cultural significance of Chinese surnames, from Li to Wang, and explore their meanings and origins."
        keywords="Chinese surnames, surname meanings, Chinese culture, family names"
        pathname="/chinese-surnames"
      />
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-gray-900">Chinese Surnames Guide</h1>
            <p className="mt-4 text-lg text-gray-500">
              Explore the rich history and meaning behind Chinese surnames
            </p>
          </div>

          {/* Search Bar */}
          <div className="mt-8 max-w-xl mx-auto">
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Search surnames by pinyin or meaning..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          {/* Surnames Grid */}
          <div className="mt-12 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {filteredSurnames.map((surname) => (
              <div
                key={surname.name}
                className="bg-white overflow-hidden shadow rounded-lg cursor-pointer hover:shadow-md transition-shadow"
                onClick={() => setSelectedSurname(surname)}
              >
                <div className="px-4 py-5 sm:p-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-3xl font-bold text-gray-900">{surname.name}</h3>
                      <p className="mt-1 text-lg text-gray-500">{surname.pinyin}</p>
                    </div>
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                      #{surname.popularity}
                    </span>
                  </div>
                  <p className="mt-3 text-gray-600">{surname.meaning}</p>
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-500">Common in:</h4>
                    <div className="mt-1 flex flex-wrap gap-2">
                      {surname.regions.map((region) => (
                        <span
                          key={region}
                          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                        >
                          {region}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Selected Surname Modal */}
          {selectedSurname && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg max-w-2xl w-full p-6">
                <div className="flex justify-between items-start">
                  <div>
                    <h2 className="text-4xl font-bold text-gray-900">
                      {selectedSurname.name}
                    </h2>
                    <p className="mt-1 text-xl text-gray-500">{selectedSurname.pinyin}</p>
                  </div>
                  <button
                    onClick={() => setSelectedSurname(null)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Close</span>
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                <div className="mt-4">
                  <h3 className="text-lg font-medium text-gray-900">Meaning & History</h3>
                  <p className="mt-2 text-gray-600">{selectedSurname.meaning}</p>
                </div>
                <div className="mt-6">
                  <h3 className="text-lg font-medium text-gray-900">Regional Distribution</h3>
                  <ul className="mt-2 space-y-2">
                    {selectedSurname.regions.map((region) => (
                      <li key={region} className="text-gray-600">• {region}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}