import React, { useState } from 'react';
import { Volume2, Play, Pause } from 'lucide-react';

interface PinyinExample {
  character: string;
  pinyin: string;
  meaning: string;
  audioUrl: string;
}

interface ToneGuide {
  tone: string;
  description: string;
  examples: PinyinExample[];
}

export function PronunciationGuide() {
  const [playing, setPlaying] = useState<string | null>(null);

  const toneGuides: ToneGuide[] = [
    {
      tone: "First Tone (ā)",
      description: "High and level, like singing a high note",
      examples: [
        {
          character: "妈",
          pinyin: "mā",
          meaning: "mother",
          audioUrl: "https://chinese-pronunciation.com/audio/ma1.mp3"
        },
        {
          character: "高",
          pinyin: "gāo",
          meaning: "high",
          audioUrl: "https://chinese-pronunciation.com/audio/gao1.mp3"
        }
      ]
    },
    {
      tone: "Second Tone (á)",
      description: "Rising tone, like asking a question",
      examples: [
        {
          character: "国",
          pinyin: "guó",
          meaning: "country",
          audioUrl: "https://chinese-pronunciation.com/audio/guo2.mp3"
        },
        {
          character: "明",
          pinyin: "míng",
          meaning: "bright",
          audioUrl: "https://chinese-pronunciation.com/audio/ming2.mp3"
        }
      ]
    },
    {
      tone: "Third Tone (ǎ)",
      description: "Falling then rising, like a dip",
      examples: [
        {
          character: "美",
          pinyin: "měi",
          meaning: "beautiful",
          audioUrl: "https://chinese-pronunciation.com/audio/mei3.mp3"
        },
        {
          character: "好",
          pinyin: "hǎo",
          meaning: "good",
          audioUrl: "https://chinese-pronunciation.com/audio/hao3.mp3"
        }
      ]
    },
    {
      tone: "Fourth Tone (à)",
      description: "Sharp falling tone, like a command",
      examples: [
        {
          character: "大",
          pinyin: "dà",
          meaning: "big",
          audioUrl: "https://chinese-pronunciation.com/audio/da4.mp3"
        },
        {
          character: "爱",
          pinyin: "ài",
          meaning: "love",
          audioUrl: "https://chinese-pronunciation.com/audio/ai4.mp3"
        }
      ]
    },
    {
      tone: "Neutral Tone",
      description: "Light and short, like a whisper",
      examples: [
        {
          character: "的",
          pinyin: "de",
          meaning: "possessive particle",
          audioUrl: "https://chinese-pronunciation.com/audio/de0.mp3"
        },
        {
          character: "吗",
          pinyin: "ma",
          meaning: "question particle",
          audioUrl: "https://chinese-pronunciation.com/audio/ma0.mp3"
        }
      ]
    }
  ];

  const playAudio = (audioUrl: string) => {
    if (playing === audioUrl) {
      setPlaying(null);
      // Stop audio logic here
    } else {
      setPlaying(audioUrl);
      // Play audio logic here
      // Note: In a real implementation, you would use the Web Audio API
      // or HTML5 Audio element to play the actual audio file
    }
  };

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Pronunciation Guide</h1>
          <p className="mt-4 text-lg text-gray-500">
            Master the tones and sounds of Chinese names
          </p>
        </div>

        {/* Introduction */}
        <div className="mt-12 max-w-3xl mx-auto">
          <div className="bg-indigo-50 rounded-lg p-6">
            <div className="flex items-center">
              <Volume2 className="h-8 w-8 text-indigo-600 mr-4" />
              <div>
                <h2 className="text-xl font-bold text-gray-900">Understanding Chinese Tones</h2>
                <p className="mt-2 text-gray-600">
                  Chinese is a tonal language where the same syllable pronounced with different tones can have different meanings. 
                  Mastering these tones is crucial for proper pronunciation of Chinese names.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Tone Guides */}
        <div className="mt-12">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {toneGuides.map((guide, index) => (
              <div
                key={index}
                className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-xl transition-shadow"
              >
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-2">
                    {guide.tone}
                  </h3>
                  <p className="text-gray-600 mb-4">
                    {guide.description}
                  </p>
                  <div className="space-y-4">
                    {guide.examples.map((example, exIndex) => (
                      <div key={exIndex} className="flex items-center justify-between">
                        <div>
                          <span className="text-2xl font-bold text-gray-900 mr-2">
                            {example.character}
                          </span>
                          <span className="text-indigo-600">{example.pinyin}</span>
                          <p className="text-sm text-gray-500">{example.meaning}</p>
                        </div>
                        <button
                          onClick={() => playAudio(example.audioUrl)}
                          className="p-2 rounded-full bg-indigo-100 hover:bg-indigo-200 transition-colors"
                        >
                          {playing === example.audioUrl ? (
                            <Pause className="h-5 w-5 text-indigo-600" />
                          ) : (
                            <Play className="h-5 w-5 text-indigo-600" />
                          )}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Practice Tips */}
        <div className="mt-16">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900">Practice Tips</h2>
            <div className="mt-8 grid gap-6 md:grid-cols-3">
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-900">Listen Carefully</h3>
                <p className="mt-2 text-gray-600">
                  Pay attention to native speakers and try to mimic their tone patterns.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-900">Practice Daily</h3>
                <p className="mt-2 text-gray-600">
                  Spend a few minutes each day practicing the different tones.
                </p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="text-lg font-semibold text-gray-900">Record Yourself</h3>
                <p className="mt-2 text-gray-600">
                  Compare your pronunciation with the example audio files.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}