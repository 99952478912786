import React, { useState, useEffect } from 'react';
import { Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import { SeoKeywordsNav } from '../seo/SeoKeywordsNav';
import { PartnerLinks } from '../footer/PartnerLinks';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // 监听滚动事件，添加阴影效果
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <header className={`fixed top-0 left-0 right-0 z-50 bg-white transition-shadow duration-200 ${
        isScrolled ? 'shadow-md' : 'shadow-sm'
      }`}>
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="flex items-center hover:text-indigo-600 transition-colors">
              <Globe className="h-8 w-8 text-indigo-600" />
              <span className="ml-2 text-xl font-bold text-gray-900">Chinese Name Generator</span>
            </Link>
          </div>
          <div className="hidden md:flex space-x-8">
            <Link to="/" className="text-gray-600 hover:text-indigo-600 transition-colors">Home</Link>
            <Link to="/name-generator" className="text-gray-600 hover:text-indigo-600 transition-colors">Generate Name</Link>
            <Link to="/chinese-surnames" className="text-gray-600 hover:text-indigo-600 transition-colors">Chinese Surnames</Link>
            <Link to="/cultural-insights" className="text-gray-600 hover:text-indigo-600 transition-colors">Cultural Insights</Link>
            <Link to="/faq" className="text-gray-600 hover:text-indigo-600 transition-colors">FAQ</Link>
          </div>
          
          {/* Mobile menu button */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none"
            >
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </nav>

        {/* Mobile menu */}
        <div className={`md:hidden transition-all duration-200 ease-in-out ${isMobileMenuOpen ? 'max-h-64' : 'max-h-0 overflow-hidden'}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 bg-white shadow-lg">
            <Link to="/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              Home
            </Link>
            <Link to="/name-generator" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              Generate Name
            </Link>
            <Link to="/chinese-surnames" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              Chinese Surnames
            </Link>
            <Link to="/cultural-insights" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              Cultural Insights
            </Link>
            <Link to="/faq" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">
              FAQ
            </Link>
          </div>
        </div>
      </header>

      {/* Add padding to main content to prevent it from being hidden under the fixed header */}
      <main className="flex-grow pt-16">
        {children}
      </main>

      {/* SEO Keywords Navigation */}
      <SeoKeywordsNav />

      <footer className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Navigation</h3>
              <div className="mt-4 space-y-4">
                <Link to="/" className="text-base text-gray-500 hover:text-gray-900 block">Home</Link>
                <Link to="/name-generator" className="text-base text-gray-500 hover:text-gray-900 block">Generate Name</Link>
                <Link to="/chinese-surnames" className="text-base text-gray-500 hover:text-gray-900 block">Chinese Surnames</Link>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Resources</h3>
              <div className="mt-4 space-y-4">
                <Link to="/cultural-insights" className="text-base text-gray-500 hover:text-gray-900 block">Cultural Insights</Link>
                <Link to="/faq" className="text-base text-gray-500 hover:text-gray-900 block">FAQ</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Partner Links */}
      <PartnerLinks />
    </div>
  );
}