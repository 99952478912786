import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

export function FAQ() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const faqs = [
    {
      question: 'Why do I need a Chinese name?',
      answer: 'Having a Chinese name helps you integrate better into Chinese culture, shows respect for the language, and makes it easier for Chinese speakers to address you. It\'s particularly important for business, education, and cultural exchange.',
    },
    {
      question: 'How do I properly pronounce my Chinese name?',
      answer: 'Each suggestion comes with pinyin (romanization system) that shows you how to pronounce your name. We also provide audio pronunciation guides and tone markers to help you master the correct pronunciation.',
    },
    {
      question: 'What is the cultural significance of Chinese surnames?',
      answer: 'Chinese surnames carry deep historical and cultural meaning. They often reflect ancient family origins, geographical locations, or historical occupations. Understanding your Chinese surname adds depth to your cultural connection.',
    },
    {
      question: 'Can I request multiple name suggestions?',
      answer: 'Yes! Our system provides three carefully selected suggestions for each request. Each suggestion comes with detailed cultural explanations and meaning. You can generate new suggestions if you\'d like more options.',
    },
    {
      question: 'Are the name cards suitable for formal use?',
      answer: 'Yes, our name cards are designed to be professional and culturally appropriate. They include both Chinese characters and pinyin, making them perfect for business cards, academic settings, and formal introductions.',
    },
    {
      question: 'How do I choose the most suitable Chinese name?',
      answer: 'Consider the meaning, pronunciation, and cultural context of each suggestion. We provide detailed explanations to help you understand the significance of each name component and choose one that best reflects your identity.',
    },
  ];

  return (
    <div className="py-16 bg-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">Frequently Asked Questions</h2>
          <p className="mt-4 text-lg text-gray-500">
            Everything you need to know about getting your Chinese name
          </p>
        </div>

        <div className="mt-12">
          <dl className="space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b border-gray-200 pb-6">
                <dt 
                  className="text-lg cursor-pointer"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <div className="flex justify-between items-center text-left">
                    <span className="font-medium text-gray-900 hover:text-indigo-600">
                      {faq.question}
                    </span>
                    {openIndex === index ? (
                      <ChevronUp className="h-5 w-5 text-indigo-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </div>
                </dt>
                <dd 
                  className={`mt-2 pr-12 transition-all duration-300 ease-in-out ${
                    openIndex === index ? 'block opacity-100' : 'hidden opacity-0'
                  }`}
                >
                  <p className="text-base text-gray-500">{faq.answer}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}