import React from 'react';
import { PenTool, Search, Download } from 'lucide-react';

export function HowItWorks() {
  const steps = [
    {
      icon: <PenTool className="h-12 w-12 text-indigo-600" />,
      title: 'Enter Your Name',
      description: 'Start by entering your English name and surname.',
    },
    {
      icon: <Search className="h-12 w-12 text-indigo-600" />,
      title: 'AI Analysis',
      description: 'Our AI analyzes your name and suggests culturally appropriate Chinese names.',
    },
    {
      icon: <Download className="h-12 w-12 text-indigo-600" />,
      title: 'Get Your Name Card',
      description: 'Choose your favorite suggestion and download a beautiful name card.',
    },
  ];

  return (
    <div className="py-16 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">How It Works</h2>
          <p className="mt-4 text-lg text-gray-500">
            Three simple steps to get your perfect Chinese name
          </p>
        </div>

        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {steps.map((step, index) => (
              <div key={index} className="text-center">
                <div className="flex justify-center mb-4">
                  {step.icon}
                </div>
                <h3 className="text-xl font-medium text-gray-900 mb-2">{step.title}</h3>
                <p className="text-gray-500">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}