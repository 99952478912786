import React from 'react';
import { Book, Users, Heart, Star, Compass, Sparkles } from 'lucide-react';
import { SeoMeta } from '../components/seo/SeoMeta';

interface CulturalTopic {
  icon: React.ReactNode;
  title: string;
  description: string;
  content: string;
}

export function CulturalInsights() {
  const topics: CulturalTopic[] = [
    {
      icon: <Book className="h-8 w-8 text-indigo-600" />,
      title: "Name Structure",
      description: "Understanding Chinese name composition",
      content: "Chinese names typically consist of a surname (family name) followed by a given name. Surnames are usually one character, while given names can be one or two characters. The surname comes first, reflecting the cultural emphasis on family identity before individual identity."
    },
    {
      icon: <Users className="h-8 w-8 text-indigo-600" />,
      title: "Family Values",
      description: "The role of family in naming",
      content: "Names often reflect family values and aspirations. Parents choose characters that embody their hopes for their child's future, incorporating virtues like wisdom, kindness, or strength. Some families maintain generation names, where siblings or cousins share a character in their given names."
    },
    {
      icon: <Heart className="h-8 w-8 text-indigo-600" />,
      title: "Gender in Names",
      description: "How gender influences name selection",
      content: "While Chinese names are generally gender-neutral, certain characters are traditionally associated with masculine or feminine qualities. For example, characters relating to strength or mountains might be used more often in boys' names, while those relating to beauty or grace might be more common in girls' names."
    },
    {
      icon: <Star className="h-8 w-8 text-indigo-600" />,
      title: "Cultural Elements",
      description: "Traditional elements in naming",
      content: "Chinese names often incorporate elements from nature, literature, and philosophy. Characters might reference the five elements (wood, fire, earth, metal, water), seasons, virtues from Confucian teachings, or poetic imagery from classical literature."
    },
    {
      icon: <Compass className="h-8 w-8 text-indigo-600" />,
      title: "Regional Variations",
      description: "Geographic influences on names",
      content: "Naming practices can vary by region and dialect group. Southern Chinese might prefer certain characters over others, while northern regions might have different traditions. Some regions have unique naming customs influenced by local culture and history."
    },
    {
      icon: <Sparkles className="h-8 w-8 text-indigo-600" />,
      title: "Modern Trends",
      description: "Contemporary naming practices",
      content: "Modern Chinese naming practices often blend tradition with contemporary values. While many parents still choose classical characters, there's a trend toward unique combinations and rarer characters. Some parents also consider international usage when naming children."
    }
  ];

  return (
    <>
      <SeoMeta
        title="Chinese Name Culture & Traditions | Naming Customs Guide"
        description="Discover the rich traditions behind Chinese names. Learn how Chinese names are structured, their cultural significance, and the art of choosing meaningful Chinese characters."
        keywords="chinese name culture, chinese name meaning, traditional chinese names, chinese name structure"
        pathname="/cultural-insights"
      />
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-gray-900">Cultural Insights</h1>
            <p className="mt-4 text-lg text-gray-500">
              Discover the rich cultural traditions behind Chinese names
            </p>
          </div>

          <div className="mt-16">
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {topics.map((topic, index) => (
                <div
                  key={index}
                  className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="p-6">
                    <div className="flex items-center justify-center w-12 h-12 rounded-md bg-indigo-50 mb-4">
                      {topic.icon}
                    </div>
                    <h3 className="text-xl font-bold text-gray-900 mb-2">
                      {topic.title}
                    </h3>
                    <p className="text-sm text-indigo-600 mb-4">
                      {topic.description}
                    </p>
                    <p className="text-gray-600">
                      {topic.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Additional Resources Section */}
          <div className="mt-16">
            <div className="text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">Additional Resources</h2>
              <p className="mt-4 text-lg text-gray-500">
                Deepen your understanding of Chinese naming culture
              </p>
            </div>

            <div className="mt-8 grid gap-4 md:grid-cols-2">
              <a
                href="/name-meanings"
                className="block p-6 bg-gradient-to-r from-indigo-50 to-blue-50 rounded-lg hover:shadow-md transition-shadow"
              >
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Explore Name Meanings
                </h3>
                <p className="text-gray-600">
                  Discover the meanings behind common Chinese characters used in names.
                </p>
              </a>
              <a
                href="/pronunciation"
                className="block p-6 bg-gradient-to-r from-purple-50 to-pink-50 rounded-lg hover:shadow-md transition-shadow"
              >
                <h3 className="text-xl font-bold text-gray-900 mb-2">
                  Pronunciation Guide
                </h3>
                <p className="text-gray-600">
                  Learn how to properly pronounce Chinese names with our interactive guide.
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}