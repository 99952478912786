import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SeoMetaProps {
  title: string;
  description: string;
  keywords: string;
  pathname: string;
}

const baseUrl = 'https://chinese-name-generator.com'; // 替换为实际的网站域名
const organizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Chinese Name Generator",
  "url": baseUrl,
  "logo": `${baseUrl}/favicon/android-chrome-512x512.png`,
  "sameAs": [
    "https://twitter.com/chinesenames",
    "https://facebook.com/chinesenames",
    "https://linkedin.com/company/chinese-name-generator"
  ]
};

export function SeoMeta({ title, description, keywords, pathname }: SeoMetaProps) {
  const canonicalUrl = `${baseUrl}${pathname}`;
  
  // 为不同页面生成特定的Schema.org标记
  const getPageSpecificSchema = () => {
    const baseSchema = {
      "@context": "https://schema.org",
      "url": canonicalUrl,
      "name": title,
      "description": description
    };

    switch (pathname) {
      case '/':
        return {
          ...baseSchema,
          "@type": "WebApplication",
          "applicationCategory": "UtilityApplication",
          "operatingSystem": "All"
        };
      case '/chinese-surnames':
        return {
          ...baseSchema,
          "@type": "Article",
          "articleSection": "Chinese Culture",
          "articleBody": "Guide to Chinese surnames and their meanings"
        };
      case '/cultural-insights':
        return {
          ...baseSchema,
          "@type": "Article",
          "articleSection": "Education",
          "articleBody": "Information about Chinese naming culture and traditions"
        };
      case '/faq':
        return {
          ...baseSchema,
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "How are Chinese names structured?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Chinese names typically consist of a family name (surname) followed by a given name. Surnames are usually one character, while given names can be one or two characters."
              }
            }
          ]
        };
      default:
        return baseSchema;
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      
      {/* Canonical URL */}
      <link rel="canonical" href={canonicalUrl} />
      
      {/* Favicon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${baseUrl}/og-image.jpg`} />
      <meta property="og:site_name" content="Chinese Name Generator" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@chinesenames" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${baseUrl}/twitter-card.jpg`} />
      
      {/* Additional Meta Tags */}
      <meta name="robots" content="index, follow" />
      <meta name="language" content="English" />
      <meta name="author" content="Chinese Name Generator" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      {/* Schema.org JSON-LD */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(getPageSpecificSchema())}
      </script>
    </Helmet>
  );
}
