import React, { useState } from 'react';
import { Card, CardContent } from "@/components/ui/card";
import { HelpCircle, Book, User, Globe, MessageCircle } from 'lucide-react';
import { SeoMeta } from '../components/seo/SeoMeta';

interface FAQItem {
  question: string;
  answer: string;
  category: string;
  icon: React.ReactNode;
}

export function FAQ() {
  const [activeCategory, setActiveCategory] = useState<string>('all');
  
  const categories = [
    { id: 'all', name: 'All Questions', icon: <HelpCircle className="h-5 w-5" /> },
    { id: 'basics', name: 'Basic Knowledge', icon: <Book className="h-5 w-5" /> },
    { id: 'naming', name: 'Naming Rules', icon: <User className="h-5 w-5" /> },
    { id: 'culture', name: 'Cultural Traditions', icon: <Globe className="h-5 w-5" /> },
    { id: 'usage', name: 'Usage Guide', icon: <MessageCircle className="h-5 w-5" /> },
  ];

  const faqItems: FAQItem[] = [
    // Usage Guide Questions
    {
      question: "How to use the Chinese Name Generator?",
      answer: "Simply enter your English first name and surname, and our system will generate multiple Chinese name suggestions that align with cultural meanings. Each suggestion comes with complete explanations, pronunciation guides, and cultural significance.",
      category: "usage",
      icon: <MessageCircle className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "Can I use the generated Chinese names directly?",
      answer: "Yes, our generated Chinese names are carefully selected considering both cultural and linguistic aspects, ensuring beautiful pronunciation and appropriate meanings. However, we recommend having a native Chinese speaker verify the name before using it in formal situations.",
      category: "usage",
      icon: <MessageCircle className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "How do I choose the most suitable Chinese name?",
      answer: "Consider these key points: 1. Is the pronunciation easy to remember? 2. Does the meaning match your personality? 3. Is the overall combination harmonious? 4. Does the cultural connotation meet your expectations? You can save multiple candidate names and make a decision after careful consideration.",
      category: "usage",
      icon: <MessageCircle className="h-6 w-6 text-indigo-600" />
    },

    // Basic Knowledge Questions
    {
      question: "What is the structure of Chinese names?",
      answer: "Chinese names consist of a surname (family name) followed by a given name. The surname is typically one character, while the given name can be one or two characters. The surname comes first, which is opposite to the English name order.",
      category: "basics",
      icon: <Book className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "Why do some Chinese names have two characters while others have three?",
      answer: "In Chinese names, the surname is usually one character (with rare exceptions like Ouyang, Sima which are two characters). The given name can be either one or two characters, based on personal preference. Two-character given names are more common as they can express richer meanings.",
      category: "basics",
      icon: <Book className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "How important is pronunciation in Chinese names?",
      answer: "Pronunciation is crucial. Chinese is a tonal language, where the same pinyin with different tones can express completely different meanings. A good Chinese name should not only look good in writing but also sound harmonious when spoken, with well-matched tones.",
      category: "basics",
      icon: <Book className="h-6 w-6 text-indigo-600" />
    },

    // Naming Rules Questions
    {
      question: "How to choose an appropriate Chinese surname?",
      answer: "There are two common approaches: 1. Choose a Chinese surname that sounds similar to your English surname. 2. Select a surname with special meaning or historical significance. Our system will recommend the most suitable Chinese surname options based on your English surname.",
      category: "naming",
      icon: <User className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "What taboos should I be aware of when choosing a Chinese name?",
      answer: "When choosing a Chinese name, consider these points: 1. Avoid rare characters that are difficult to read. 2. Avoid characters with negative or inappropriate meanings. 3. Pay attention to tone combinations. 4. Avoid names of famous figures or brands. 5. Consider the name's appropriateness in different contexts.",
      category: "naming",
      icon: <User className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "Does the number of characters affect fortune?",
      answer: "While traditional Chinese numerology suggests that the number of characters in a name can affect fortune, modern naming practices focus more on practicality and aesthetics. Whether you choose a one or two-character given name, the most important factors are that you like the name and it sounds pleasant with good meaning.",
      category: "naming",
      icon: <User className="h-6 w-6 text-indigo-600" />
    },

    // Cultural Questions
    {
      question: "What are the traditional Chinese naming customs?",
      answer: "Chinese naming traditions are profound, including: 1. Choosing auspicious meanings that express hopes for the child. 2. Reflecting family heritage through generation names. 3. Considering the balance of five elements. 4. Emphasizing beautiful character meanings. 5. Seeking lucky-sounding pronunciations. Modern naming retains these traditions while embracing individuality and internationalization.",
      category: "culture",
      icon: <Globe className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "Why are some characters unsuitable for names?",
      answer: "Certain characters are unsuitable for names because: 1. They are too complex to write. 2. They carry negative or inappropriate meanings. 3. They are too obscure and difficult to recognize. 4. They have cultural taboos. 5. They may cause misunderstandings or confusion. Our system automatically filters out these unsuitable characters.",
      category: "culture",
      icon: <Globe className="h-6 w-6 text-indigo-600" />
    },
    {
      question: "How much influence does a Chinese name have on a person?",
      answer: "In Chinese culture, names are believed to influence one's personality and destiny. A good name can boost confidence, bring good fortune, and create positive first impressions. While this belief has cultural roots, a well-chosen name with pleasant sound and meaningful characters can indeed bring positive influences to life.",
      category: "culture",
      icon: <Globe className="h-6 w-6 text-indigo-600" />
    }
  ];

  return (
    <>
      <SeoMeta
        title="Chinese Names FAQ | Everything About Chinese Name Meanings"
        description="Get answers to common questions about Chinese names. Learn about pronunciation, character selection, name meanings, and how to choose an appropriate Chinese name."
        keywords="chinese name meaning, how to choose chinese name, chinese name pronunciation, chinese name characters"
        pathname="/faq"
      />
      <div className="container mx-auto px-4 py-8">
        {/* 标题部分 */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            All important information about Chinese names and our name generation service
          </p>
        </div>

        {/* 分类过滤器 */}
        <div className="flex flex-wrap gap-4 justify-center mb-8">
          {categories.map((category) => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors duration-200 ${
                activeCategory === category.id
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
              }`}
            >
              {category.icon}
              <span>{category.name}</span>
            </button>
          ))}
        </div>

        {/* FAQ卡片列表 */}
        <div className="space-y-4 max-w-3xl mx-auto">
          {faqItems
            .filter(item => activeCategory === 'all' || item.category === activeCategory)
            .map((item, index) => (
              <Card 
                key={index} 
                className="hover:shadow-lg transition-all duration-200 hover:border-indigo-200"
              >
                <CardContent className="p-6">
                  <div className="flex items-start gap-4">
                    {item.icon}
                    <div>
                      <h2 className="text-xl font-semibold mb-3">{item.question}</h2>
                      <p className="text-gray-600">{item.answer}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
        </div>
      </div>
    </>
  );
}
