import React from 'react';

interface KeywordCategory {
  title: string;
  keywords: string[];
}

const keywordCategories: KeywordCategory[] = [
  {
    title: "Name Essentials",
    keywords: [
      "Chinese Name Generator",
      "Chinese Names for Girls",
      "Chinese Names for Boys",
      "Chinese Name Meaning",
      "Chinese Name Translation",
      "Traditional Chinese Names"
    ]
  },
  {
    title: "Cultural Knowledge",
    keywords: [
      "Chinese Surname Guide",
      "Chinese Name Structure",
      "Name Pronunciation",
      "Chinese Name Characters",
      "Name Culture Guide"
    ]
  },
  {
    title: "Name Categories",
    keywords: [
      "Beautiful Chinese Names",
      "Modern Chinese Names",
      "Popular Chinese Names",
      "Unique Chinese Names",
      "Gender-Neutral Names"
    ]
  },
  {
    title: "Helpful Resources",
    keywords: [
      "Name Generator Tool",
      "Chinese Name FAQ",
      "Naming Guidelines",
      "Cultural Insights",
      "Choose Your Name"
    ]
  }
];

export function SeoKeywordsNav() {
  return (
    <div className="bg-gray-50 py-8 border-t border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {keywordCategories.map((category, index) => (
            <div key={index} className="space-y-4">
              <h3 className="text-sm font-semibold text-gray-900 uppercase tracking-wider">
                {category.title}
              </h3>
              <ul className="space-y-2">
                {category.keywords.map((keyword, keywordIndex) => (
                  <li key={keywordIndex}>
                    <span className="text-sm text-gray-500">
                      {keyword}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
