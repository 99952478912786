import { GenerateNameRequest, GenerateNameResponse } from '../../types/name';

// API Configuration Logging
console.log('=== API Configuration ===');
console.log('Environment:', import.meta.env.PROD ? 'production' : 'development');

// API Configuration
const API_CONFIG = {
  BASE_URL: import.meta.env.PROD 
    ? window.location.hostname.includes('chinese-name.net')
      ? window.location.origin  // 使用当前域名作为BASE_URL
      : 'https://ai-chinese-name04.vercel.app'
    : '',  // 开发环境使用相对路径
  ENDPOINTS: {
    GENERATE_NAME: '/api/generate-name'
  },
  TIMEOUT: 60000 // 60 seconds timeout
};

// Log API configuration for debugging
console.log('Current hostname:', window.location.hostname);
console.log('Selected BASE_URL:', API_CONFIG.BASE_URL);

// Custom Error Class
class APIError extends Error {
  constructor(
    public message: string,
    public statusCode?: number,
    public type: 'network' | 'timeout' | 'validation' | 'server' = 'server',
    public details?: any
  ) {
    super(message);
    this.name = 'APIError';
  }
}

// Configuration Constants
const CONFIG = {
  TIMEOUT: 60000,       // 60 seconds timeout
  RETRY_COUNT: 2,       // Maximum retry attempts
  RETRY_DELAY: 2000,    // 2 seconds delay between retries
} as const;

// Create timeout Promise
const timeoutPromise = (ms: number) =>
  new Promise((_, reject) =>
    setTimeout(() => reject(new APIError('Request timed out', undefined, 'timeout')), ms)
  );

// Delay function
const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Validate request parameters
const validateRequest = (request: GenerateNameRequest): void => {
  if (!request.firstName?.trim()) {
    throw new APIError('First name is required', 400, 'validation');
  }
  if (!request.surname?.trim()) {
    throw new APIError('Surname is required', 400, 'validation');
  }
};

// Get full URL
const getFullUrl = (path: string) => {
  return `${API_CONFIG.BASE_URL}${path}`;
};

// Make single request
const makeRequest = async (
  firstName: string,
  surname: string,
  signal: AbortSignal
): Promise<Response> => {
  console.log('Making request to:', API_CONFIG.ENDPOINTS.GENERATE_NAME);
  
  const response = await fetch(getFullUrl(API_CONFIG.ENDPOINTS.GENERATE_NAME), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ firstName, surname }),
    signal
  });

  if (!response.ok) {
    let errorData;
    try {
      errorData = await response.text();
    } catch {
      errorData = 'Failed to read error response';
    }

    throw new APIError(
      `API request failed: ${errorData}`,
      response.status,
      'server',
      errorData
    );
  }

  return response;
};

// Generate Chinese Name
export const generateChineseName = async (
  firstName: string,
  surname: string
): Promise<GenerateNameResponse> => {
  console.log('=== Starting Name Generation Request ===');
  console.log('Request Parameters:', { firstName, surname });

  try {
    validateRequest({ firstName, surname });

    let lastError: Error | null = null;

    for (let attempt = 0; attempt <= CONFIG.RETRY_COUNT; attempt++) {
      if (attempt > 0) {
        console.log(`Retry attempt ${attempt}/${CONFIG.RETRY_COUNT}`);
        await delay(CONFIG.RETRY_DELAY);
      }

      const controller = new AbortController();
      const timeoutId = setTimeout(() => {
        try {
          controller.abort();
        } catch (e) {
          console.error('Failed to abort request:', e);
        }
      }, CONFIG.TIMEOUT);

      try {
        const response = await makeRequest(firstName, surname, controller.signal);
        
        console.log('Response received:');
        console.log('Status:', response.status);
        console.log('Status text:', response.statusText);

        const data = await response.json();
        clearTimeout(timeoutId);

        if (!data.suggestions || !Array.isArray(data.suggestions)) {
          throw new APIError('Invalid API response: missing or invalid suggestions array');
        }

        return data as GenerateNameResponse;
      } catch (error) {
        lastError = error as Error;
        clearTimeout(timeoutId);

        if (error instanceof APIError && error.type === 'timeout') {
          console.log(`Attempt ${attempt + 1} timed out`);
          continue;
        }
        
        if (error.name === 'AbortError') {
          console.log(`Attempt ${attempt + 1} was aborted`);
          continue;
        }

        console.log(`Attempt ${attempt + 1} failed:`, error);
        
        if (attempt === CONFIG.RETRY_COUNT) {
          break;
        }
      }
    }

    // If we've exhausted all retries, throw the last error
    console.log('\n=== Name Generation Process Failed ===');
    console.log('Error Type:', lastError instanceof APIError ? lastError.type : 'unknown');
    console.log('Error Message:', lastError?.message || 'Unknown error');
    console.log('Full Error:', lastError);
    
    throw lastError || new APIError('Maximum retry attempts reached');
  } catch (error) {
    console.log('\n=== Name Generation Process Error ===');
    console.log('Error Type:', error instanceof APIError ? error.type : 'unknown');
    console.log('Error Message:', error instanceof Error ? error.message : String(error));
    console.log('Full Error:', error);
    throw error;
  }
};