import React, { useState } from 'react';
import { NameCard } from '../components/name-generator/NameCard';
import { NamePreview } from '../components/name-generator/NamePreview';
import { NameGeneratorForm } from '../components/name-generator/NameGeneratorForm';
import { NameSuggestion } from '../types/name';
import { SeoMeta } from '../components/seo/SeoMeta';
import { generateChineseName } from '../lib/api/nameGenerator';

export const NameGenerator: React.FC = () => {
  const [suggestions, setSuggestions] = useState<NameSuggestion[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState<string>('');

  const handleGenerateNames = async (firstName: string, surname: string) => {
    setIsLoading(true);
    setError(null);
    setProgress('Generating name suggestions...');
    setSuggestions([]);
    
    try {
      const response = await generateChineseName(firstName, surname);
      
      // Update suggestions list
      setSuggestions(response.suggestions);
      setSelectedIndex(0);
      
      // Show error message if any
      if (response.errors) {
        setError(`Name generation partially failed: ${response.errors.join('; ')}`);
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error occurred while generating names');
      setSuggestions([]);
    } finally {
      setIsLoading(false);
      setProgress('');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <SeoMeta
        title="AI Chinese Name Generator | Get Your Perfect Chinese Name"
        description="Generate your personalized Chinese name with our AI tool. Get authentic names with pinyin pronunciation, character meanings, and cultural explanations. Male & female options available."
        keywords="generate chinese name, chinese name creator, chinese name translation, personalized chinese name"
        pathname="/generate"
      />

      <div className="text-center mb-8">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">
          Chinese Name Generator
        </h1>
        <p className="text-xl text-gray-600">
          Transform your English name into a meaningful Chinese name
        </p>
      </div>

      {/* 名字生成表单 */}
      <div className="mb-12">
        <NameGeneratorForm onSubmit={handleGenerateNames} isLoading={isLoading} />
        
        {/* 进度提示 */}
        {progress && (
          <div className="mt-4 text-center text-indigo-600">
            <div className="inline-flex items-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {progress}
            </div>
          </div>
        )}
        
        {/* 错误提示 */}
        {error && (
          <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
            {error}
          </div>
        )}
      </div>

      {/* 名字卡片列表 */}
      {suggestions.length > 0 && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
            {suggestions.map((suggestion, index) => (
              <div key={index} className={`transition-opacity duration-500 ${isLoading ? 'opacity-0' : 'opacity-100'}`}>
                <NameCard
                  suggestion={suggestion}
                  isActive={index === selectedIndex}
                  onClick={() => setSelectedIndex(index)}
                />
              </div>
            ))}
          </div>

          {/* 名字预览和导出 */}
          <NamePreview suggestion={suggestions[selectedIndex]} />
        </>
      )}
    </div>
  );
};
