import React from 'react';
import { BrowserRouter, Routes, Route, createRoutesFromElements } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { Home } from './pages/Home';
import { NameGenerator } from './pages/NameGenerator';
import { ChineseSurnames } from './pages/ChineseSurnames';
import { CulturalInsights } from './pages/CulturalInsights';
import { NameMeanings } from './pages/NameMeanings';
import { PronunciationGuide } from './pages/PronunciationGuide';
import { FAQ } from './pages/FAQ';

function App() {
  return (
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/name-generator" element={<NameGenerator />} />
          <Route path="/chinese-surnames" element={<ChineseSurnames />} />
          <Route path="/cultural-insights" element={<CulturalInsights />} />
          <Route path="/name-meanings" element={<NameMeanings />} />
          <Route path="/pronunciation" element={<PronunciationGuide />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;