import React, { useState } from 'react';
import { Search } from 'lucide-react';

interface Character {
  character: string;
  pinyin: string;
  meaning: string;
  usage: string;
  elements: string[];
}

export function NameMeanings() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const categories = [
    "Virtues",
    "Nature",
    "Aspirations",
    "Qualities",
    "Elements",
    "All"
  ];

  const characters: Character[] = [
    {
      character: "德",
      pinyin: "Dé",
      meaning: "Virtue, morality",
      usage: "Often used in names to represent moral character and virtue",
      elements: ["Virtues"]
    },
    {
      character: "智",
      pinyin: "Zhì",
      meaning: "Wisdom, intelligence",
      usage: "Popular in names for expressing hopes for intellectual achievement",
      elements: ["Virtues", "Qualities"]
    },
    {
      character: "永",
      pinyin: "Yǒng",
      meaning: "Forever, eternal",
      usage: "Used to express wishes for longevity and endurance",
      elements: ["Aspirations"]
    },
    {
      character: "山",
      pinyin: "Shān",
      meaning: "Mountain",
      usage: "Represents strength and stability in names",
      elements: ["Nature", "Elements"]
    },
    {
      character: "海",
      pinyin: "Hǎi",
      meaning: "Ocean, sea",
      usage: "Symbolizes vastness and depth of character",
      elements: ["Nature", "Elements"]
    },
    {
      character: "明",
      pinyin: "Míng",
      meaning: "Bright, clear",
      usage: "Common in names to represent clarity of mind and brightness",
      elements: ["Qualities", "Aspirations"]
    }
  ];

  const filteredCharacters = characters.filter(char => {
    const matchesSearch = 
      char.character.includes(searchTerm) ||
      char.pinyin.toLowerCase().includes(searchTerm.toLowerCase()) ||
      char.meaning.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesCategory = 
      !selectedCategory || 
      selectedCategory === "All" || 
      char.elements.includes(selectedCategory);

    return matchesSearch && matchesCategory;
  });

  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900">Name Character Meanings</h1>
          <p className="mt-4 text-lg text-gray-500">
            Explore the meanings and usage of Chinese characters in names
          </p>
        </div>

        {/* Search and Filter Section */}
        <div className="mt-8 max-w-xl mx-auto">
          <div className="relative mb-4">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search characters, pinyin, or meanings..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="flex flex-wrap gap-2 justify-center">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category === selectedCategory ? null : category)}
                className={`px-4 py-2 rounded-full text-sm font-medium ${
                  category === selectedCategory
                    ? 'bg-indigo-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* Characters Grid */}
        <div className="mt-12 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredCharacters.map((char) => (
            <div
              key={char.character}
              className="bg-white overflow-hidden shadow rounded-lg hover:shadow-md transition-shadow"
            >
              <div className="px-4 py-5 sm:p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="text-center flex-1">
                    <h3 className="text-5xl font-bold text-gray-900 mb-2">{char.character}</h3>
                    <p className="text-xl text-indigo-600">{char.pinyin}</p>
                  </div>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-gray-500">Meaning</h4>
                  <p className="mt-1 text-gray-900">{char.meaning}</p>
                </div>
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-500">Usage in Names</h4>
                  <p className="mt-1 text-gray-600">{char.usage}</p>
                </div>
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-500">Categories</h4>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {char.elements.map((element) => (
                      <span
                        key={element}
                        className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                      >
                        {element}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}