import React from 'react';
import { NameSuggestion } from '../../types/name';
import '../../styles/nameCard.css';

interface NameCardProps {
  suggestion: NameSuggestion;
  isActive?: boolean;
  onClick?: () => void;
}

export const NameCard: React.FC<NameCardProps> = ({ suggestion, isActive = false, onClick }) => {
  return (
    <div 
      className={`w-full max-w-md bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer ${
        isActive ? 'ring-2 ring-indigo-500' : ''
      }`}
      onClick={onClick}
    >
      <div className="p-8 space-y-6">
        {/* 中文名和拼音 */}
        <div className="text-center">
          <h2 className="text-4xl font-bold text-gray-800 mb-3 tracking-wider">
            {suggestion.chineseName}
          </h2>
          <p className="text-xl text-indigo-600 font-medium tracking-wide">{suggestion.pinyin}</p>
        </div>

        {/* 姓氏和名字分解 */}
        <div className="space-y-3">
          <h4 className="flex items-center gap-2 font-semibold text-gray-800">
            <span className="text-xl">📝</span>
            Name Components
          </h4>
          <div className="text-base text-gray-600 leading-relaxed pl-8">
            <p className="mb-2">Surname: {suggestion.chineseSurname}</p>
            <p>Given Name: {suggestion.chineseGivenName}</p>
          </div>
        </div>

        {/* 姓氏解释 */}
        <div className="space-y-3">
          <h4 className="flex items-center gap-2 font-semibold text-gray-800">
            <span className="text-xl">👨‍👩‍👧‍👦</span>
            Surname Explanation
          </h4>
          <p className="text-base text-gray-600 leading-relaxed pl-8">
            {suggestion.surnameExplanation}
          </p>
        </div>

        {/* 名字解释 */}
        <div className="space-y-3">
          <h4 className="flex items-center gap-2 font-semibold text-gray-800">
            <span className="text-xl">✨</span>
            Given Name Meaning
          </h4>
          <p className="text-base text-gray-600 leading-relaxed pl-8">
            {suggestion.givenNameExplanation}
          </p>
        </div>

        {/* 文化联结 */}
        <div className="space-y-3">
          <h4 className="flex items-center gap-2 font-semibold text-gray-800">
            <span className="text-xl">🏮</span>
            Cultural Connection
          </h4>
          <p className="text-base text-gray-600 leading-relaxed pl-8">
            {suggestion.culturalConnection}
          </p>
        </div>

        {/* 其他信息 */}
        <div className="space-y-3">
          <h4 className="flex items-center gap-2 font-semibold text-gray-800">
            <span className="text-xl">📌</span>
            Additional Notes
          </h4>
          <p className="text-base text-gray-600 leading-relaxed pl-8">
            {suggestion.additionalNotes}
          </p>
        </div>
      </div>
    </div>
  );
};