import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Testimonials } from '../components/landing/Testimonials';
import { FAQ } from '../components/landing/FAQ';
import { HowItWorks } from '../components/landing/HowItWorks';
import { SeoMeta } from '../components/seo/SeoMeta';

export function Home() {
  const navigate = useNavigate();

  return (
    <>
      <SeoMeta
        title="Chinese Name Generator | Discover Your Perfect Chinese Name"
        description="Transform your English name into a meaningful Chinese name. Our AI-powered generator creates culturally appropriate Chinese names with detailed explanations and pronunciations. Get your personalized Chinese name today!"
        keywords="chinese name generator, chinese names, chinese name meaning, chinese name translation, chinese name generator online"
        pathname="/"
      />
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-50 to-blue-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">Discover Your Perfect</span>
              <span className="block text-indigo-600">Chinese Name</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              Generate a culturally appropriate Chinese name based on your English name using AI technology.
            </p>

            <div className="mt-10">
              <button
                onClick={() => navigate('/name-generator')}
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Try Chinese Name Generator
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Why Choose Our Name Generator?
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              Discover the perfect Chinese name that reflects your identity and cultural values.
            </p>
          </div>

          <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {/* Feature 1 */}
            <div className="p-6 bg-gray-50 rounded-lg">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900">AI-Powered Analysis</h3>
                <p className="mt-2 text-base text-gray-500">
                  Our advanced AI analyzes the meaning and cultural significance of your English name.
                </p>
              </div>
            </div>

            {/* Feature 2 */}
            <div className="p-6 bg-gray-50 rounded-lg">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900">Cultural Accuracy</h3>
                <p className="mt-2 text-base text-gray-500">
                  Each suggestion respects Chinese naming conventions and cultural traditions.
                </p>
              </div>
            </div>

            {/* Feature 3 */}
            <div className="p-6 bg-gray-50 rounded-lg">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900">Beautiful Name Cards</h3>
                <p className="mt-2 text-base text-gray-500">
                  Generate elegant digital name cards with your chosen Chinese name.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <HowItWorks />

      {/* Testimonials Section */}
      <Testimonials />

      {/* FAQ Section */}
      <FAQ />

      {/* CTA Section */}
      <div className="bg-indigo-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Ready to discover your Chinese name?
          </h2>
          <div className="mt-8">
            <button
              onClick={() => navigate('/name-generator')}
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Get Started Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
}