import React from 'react';

interface Partner {
  name: string;
  url: string;
}

const partners: Partner[] = [
  {
    name: "Chinese Character Interactive Game",
    url: "https://www.zwhanzigame.com/"
  },
  {
    name: "Fertility Calendar Tools",
    url: "https://www.fertilities.org"
  },
  {
    name: "AI Methodology Guide",
    url: "https://method-mindset.com/"
  },
  {
    name: "AI Decision Assistant",
    url: "http://www.decisiondie.com"
  },
  {
    name: "AI File Manager",
    url: "http://www.filerenamer-ai.com/"
  }
];

export function PartnerLinks() {
  return (
    <div className="bg-gray-900 text-gray-400">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center space-y-2">
          <span className="text-xs uppercase tracking-wider mb-2">Partner Sites</span>
          <div className="flex flex-wrap justify-center gap-4 text-xs">
            {partners.map((partner, index) => (
              <React.Fragment key={index}>
                <a
                  href={partner.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-300 transition-colors duration-150"
                >
                  {partner.name}
                </a>
                {index < partners.length - 1 && (
                  <span className="text-gray-600">•</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
