import React, { useRef } from 'react';
import { NameSuggestion } from '../../types/name';
import html2canvas from 'html2canvas';

interface NamePreviewProps {
  suggestion: NameSuggestion | null;
}

export const NamePreview: React.FC<NamePreviewProps> = ({ suggestion }) => {
  const previewRef = useRef<HTMLDivElement>(null);

  const exportAsPNG = async () => {
    if (!previewRef.current || !suggestion) return;

    try {
      const canvas = await html2canvas(previewRef.current, {
        scale: 2,
        backgroundColor: '#ffffff'
      });
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = `${suggestion.chineseName}-name-card.png`;
      link.href = dataUrl;
      link.click();
    } catch (error) {
      console.error('Failed to export as PNG:', error);
    }
  };

  if (!suggestion) return null;

  return (
    <div className="mt-8 space-y-4">
      <h3 className="text-xl font-bold text-center text-gray-800 flex items-center justify-center gap-2">
        <span className="text-2xl">📱</span> Your Name Card Preview <span className="text-2xl">📱</span>
      </h3>
      
      {/* Card Preview */}
      <div 
        ref={previewRef}
        className="max-w-[800px] w-full mx-auto bg-white p-8 rounded-lg shadow-lg"
        style={{ aspectRatio: '4/5' }}
      >
        <div className="w-full h-full p-8 border-2 border-indigo-500 rounded-xl">
          {/* Chinese Name and Pinyin */}
          <div className="text-center mb-8">
            <h2 className="text-6xl font-bold text-gray-900 mb-4 tracking-wider">
              {suggestion.chineseName}
            </h2>
            <p className="text-2xl text-indigo-600 font-medium tracking-wide">
              {suggestion.pinyin}
            </p>
          </div>

          {/* Name Components */}
          <div className="mb-6">
            <h4 className="flex items-center gap-2 text-xl font-semibold text-gray-800 mb-2">
              <span>📝</span>
              <span>Name Components</span>
            </h4>
            <div className="text-gray-600 ml-8 space-y-1">
              <p>Surname: {suggestion.chineseSurname}</p>
              <p>Given Name: {suggestion.chineseGivenName}</p>
            </div>
          </div>

          {/* Surname Explanation */}
          <div className="mb-6">
            <h4 className="flex items-center gap-2 text-xl font-semibold text-gray-800 mb-2">
              <span>👨‍👩‍👧‍👦</span>
              <span>Surname Explanation</span>
            </h4>
            <p className="text-gray-600 ml-8">
              {suggestion.surnameExplanation}
            </p>
          </div>

          {/* Given Name Meaning */}
          <div className="mb-6">
            <h4 className="flex items-center gap-2 text-xl font-semibold text-gray-800 mb-2">
              <span>✨</span>
              <span>Given Name Meaning</span>
            </h4>
            <p className="text-gray-600 ml-8">
              {suggestion.givenNameExplanation}
            </p>
          </div>

          {/* Cultural Connection */}
          <div className="mb-6">
            <h4 className="flex items-center gap-2 text-xl font-semibold text-gray-800 mb-2">
              <span>🏮</span>
              <span>Cultural Connection</span>
            </h4>
            <p className="text-gray-600 ml-8">
              {suggestion.culturalConnection}
            </p>
          </div>

          {/* Additional Notes */}
          <div>
            <h4 className="flex items-center gap-2 text-xl font-semibold text-gray-800 mb-2">
              <span>📌</span>
              <span>Additional Notes</span>
            </h4>
            <p className="text-gray-600 ml-8">
              {suggestion.additionalNotes}
            </p>
          </div>
        </div>
      </div>

      {/* Export Button */}
      <div className="text-center">
        <button
          onClick={exportAsPNG}
          className="inline-flex items-center gap-2 px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
        >
          <span>📥</span>
          Export as PNG
        </button>
      </div>
    </div>
  );
};
